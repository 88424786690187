import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';

import { Icon, TextInput } from '~/ui';
import { IconSizes } from '~/ui/components/Icon/IconSizes';
import { TextInputVariant } from '~/ui/components/TextInput';

import { EditableTextProps, EditableTextValidation } from './types';
import { EditableTextActions } from './EditableTextActions';
import './EditableText.scss';

const ROOT_CLASS_NAME = 'editabletext';

function EditableText({
    mainContent,
    handleSubmit,
    handleValidation,
    handleCancel = () => {},
    index = 0,
    isEditable = false,
    isEditMode = false,
    showEditIcon = false,
    formatter = (value) => value,
    'data-testid': dataTestId,
    className
}: EditableTextProps) {
    const [isTextInput, setIsTextInput] = useState(isEditMode);
    const [error, setError] = useState<EditableTextValidation>(null);
    const textInputRef = useRef<HTMLInputElement | null>(null);

    // value of 0 is not considered empty
    const isMainContentEmpty = !mainContent && mainContent !== 0;
    const conditionalClasses = {
        'editable-text--enabled': isEditable,
        '_jc-space-between': !isMainContentEmpty,
        '_jc-flex-end': isMainContentEmpty
    };
    const elemClassName = classNames(
        ROOT_CLASS_NAME,
        '_d-flex',
        className,
        conditionalClasses
    );
    const iconClassName = classNames(`${ROOT_CLASS_NAME}__icon`, {
        [`${className}__icon`]: className
    });
    const editModeClassName = classNames(
        `${ROOT_CLASS_NAME}__edit-mode`,
        '_fd-column',
        'edit-mode'
    );

    const onClickCancel = useCallback(() => {
        handleCancel();
        setIsTextInput(false);
        setError(null);
    }, [handleCancel]);

    const onClickCheck = useCallback(() => {
        // no change
        if (textInputRef.current?.value === mainContent) return onClickCancel();

        const { value } = textInputRef.current || { value: '' };

        // client-side validation
        const errorMsg = handleValidation?.(value);
        if (errorMsg) return setError(errorMsg);

        // client-side validation passed
        handleSubmit?.({ index, value });
        setIsTextInput(false);
    }, [onClickCancel, handleSubmit, handleValidation, index, mainContent]);

    const onClickDetail = useCallback(() => {
        if (isEditable) setIsTextInput((state) => !state);
    }, [isEditable]);

    const rootDataTestId = dataTestId || ROOT_CLASS_NAME;
    const editModeDataTestId = `${rootDataTestId}__edit-mode`;
    const actionsDataTestId = `${rootDataTestId}__actions`;
    const iconDataTestId = `${rootDataTestId}__icon`;

    return (
        <>
            {isTextInput && (
                <div
                    className={editModeClassName}
                    data-testid={editModeDataTestId}
                >
                    <TextInput
                        ref={textInputRef}
                        value={mainContent}
                        width="fullWidth"
                        variant={
                            error
                                ? TextInputVariant.ERROR
                                : TextInputVariant.EDIT
                        }
                        data-testid="editabletext-input"
                    />
                    <EditableTextActions
                        onClickCancel={onClickCancel}
                        onClickCheck={onClickCheck}
                        error={error}
                        data-testid={actionsDataTestId}
                    />
                </div>
            )}
            {!isTextInput && (
                <span
                    className={elemClassName}
                    onClick={onClickDetail}
                    onKeyDown={onClickDetail}
                    role="button"
                    tabIndex={index}
                    data-testid={rootDataTestId}
                >
                    {formatter(mainContent)}
                    {showEditIcon && (
                        <Icon
                            data-testid={iconDataTestId}
                            size={IconSizes.M}
                            className={iconClassName}
                            icon="edit"
                        />
                    )}
                </span>
            )}
        </>
    );
}

export default EditableText;
