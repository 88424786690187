import i18n from '~/i18n';
import { isEmpty } from 'lodash';
import { Duration } from 'luxon';

import constants from '../constants';
import dateUtils from '../date-utils';
import localizationUtils from '../localization-utils';
import generalUtils from '../general-utils';

import {
    Address,
    ApiAddress,
    ApiInventoryItem,
    ApiTask,
    DeliveryTask,
    PickupTask,
    TaskStatus,
    TaskStatusCondition,
    TimeWindow,
    TwoPartTask
} from '~/api/types';
import { TaskMetrics } from '~/api/TasksApi';

const NO_CONTENT = i18n.t('N/A', { ns: 'common' });
const DYNAMIC_TASK_EUID = i18n.t('dynamic', { ns: 'common' });

function formatAddress(address?: Address | string) {
    return localizationUtils.formatAddress(address);
}

function formatServiceTime(serviceTime: string) {
    return generalUtils.roundToMaxDigitsAfterDecimal(
        Duration.fromISO(serviceTime).shiftTo('minutes').minutes,
        2
    );
}

function formatTimeWindow({
    timeWindow,
    format,
    selectedTimeZone = '',
    isClientTimezoneFlagEnabled = false
}: {
    timeWindow: TimeWindow[];
    selectedTimeZone?: string;
    isClientTimezoneFlagEnabled?: boolean;
    format?: string;
}) {
    return (timeWindow || []).map(({ start, end }) => {
        if (dateUtils.isValidDateISO(start)) {
            return dateUtils.getTimeWindowInTimeZone({
                start,
                end,
                timeZone: selectedTimeZone,
                isClientTimezoneFlagEnabled,
                format
            });
        }
        return dateUtils.getTimeWindow(start, end);
    });
}

function formatTimeWindowWithDate({
    timeWindow,
    selectedTimeZone,
    isClientTimezoneFlagEnabled,
    format
}: {
    timeWindow: TimeWindow[];
    selectedTimeZone?: string;
    isClientTimezoneFlagEnabled: boolean;
    format?: string;
}) {
    return (timeWindow || []).map(({ start, end }) => {
        const formattedTimeWindow = dateUtils.getTimeWindowInTimeZone({
            start,
            end,
            timeZone: selectedTimeZone,
            isClientTimezoneFlagEnabled,
            format
        });
        const date = dateUtils.convertISODateToJsDate(start);

        return !date
            ? formattedTimeWindow
            : `${formattedTimeWindow}, ${dateUtils.formatMonthDay(date)}`;
    });
}

function formatTimestamp(timestamp: string) {
    const date = dateUtils.convertISODateToJsDate(timestamp);
    if (!timestamp || !date) return null;

    return `${dateUtils.formatMonthDay(date)} ${dateUtils.getLocalizedTime(
        timestamp
    )}`;
}

function formatTaskName(task: ApiTask) {
    const isTwoPart = checkIsTwoPartTask(task);
    const isDelivery = checkIsDeliveryTask(task);
    const isPickup = checkIsPickupTask(task);

    if (isTwoPart) {
        const pickupLocationName = task.pickupLocation?.name;
        const deliveryLocationName = task.deliveryLocation?.name;
        return (
            pickupLocationName &&
            deliveryLocationName &&
            `${pickupLocationName} - ${deliveryLocationName}`
        );
    }

    if (isDelivery) return task.deliveryLocation?.name;
    if (isPickup) return task.pickupLocation?.name;
}

function formatTaskAddress(task: ApiTask) {
    const isTwoPart = checkIsTwoPartTask(task);
    const isDelivery = checkIsDeliveryTask(task);
    const isPickup = checkIsPickupTask(task);

    if (isTwoPart) {
        const { pickupLocation, deliveryLocation } = task;
        const taskAddress = `${formatAddress(pickupLocation)} - ${formatAddress(
            deliveryLocation
        )}`;
        return taskAddress;
    }
    if (isDelivery) return formatAddress(task.deliveryLocation);
    if (isPickup) return formatAddress(task.pickupLocation);
}

function getEuid(euid?: string | null) {
    return euid || DYNAMIC_TASK_EUID;
}

function getTaskLocationKey(type?: string) {
    return type?.toLowerCase() === constants.taskTypes.PICKUP
        ? constants.taskTypes.PICKUP_LOCATION
        : constants.taskTypes.DELIVERY_LOCATION;
}
function getTaskInventoryKey(type?: string) {
    return type?.toLowerCase() === constants.taskTypes.PICKUP
        ? constants.taskTypes.PICKUP_INVENTORY
        : constants.taskTypes.DELIVERY_INVENTORY;
}

const getTaskLocationLatLng = (
    taskLocation: Pick<ApiAddress, 'location' | 'geoLocation'>
) => {
    if (taskLocation?.location) return taskLocation.location;

    if (taskLocation?.geoLocation?.coordinates) {
        const [lng, lat] = taskLocation.geoLocation.coordinates;
        return { lat, lng };
    }

    return {
        lat: null,
        lng: null
    };
};

function checkIsDepot(task: ApiTask) {
    return task?.props?.type === constants.taskTypes.DEPOT;
}

function checkIsTwoPartTask(task: ApiTask): task is TwoPartTask {
    return Boolean(
        (task as TwoPartTask)?.[constants.taskTypes.PICKUP_LOCATION] &&
            (task as TwoPartTask)?.[constants.taskTypes.DELIVERY_LOCATION]
    );
}

function checkIsDeliveryTask(task: ApiTask): task is DeliveryTask {
    return Boolean(
        !(task as TwoPartTask)?.[constants.taskTypes.PICKUP_LOCATION] &&
            (task as DeliveryTask)?.[constants.taskTypes.DELIVERY_LOCATION]
    );
}

function checkIsPickupTask(task: ApiTask): task is PickupTask {
    return Boolean(
        (task as PickupTask)?.[constants.taskTypes.PICKUP_LOCATION] &&
            !(task as TwoPartTask)?.[constants.taskTypes.DELIVERY_LOCATION]
    );
}

function getCommonFilteredTaskDetail(task: ApiTask) {
    const {
        props,
        externalTaskType,
        labels,
        taskStatus,
        createdAt,
        updatedAt,
        routeId,
        sizeByCompartment,
        euid,
        equipmentId,
        reservation,
        emptyOrder
    } = task;
    const { notes, externalLinks, priority, weight, size } = props;
    const commonDetails = {
        taskName: formatTaskName(task),
        taskAddress: formatTaskAddress(task),
        externalTaskType: externalTaskType || NO_CONTENT,
        labels: labels ?? [],
        notes: notes?.length ? notes : [{ text: NO_CONTENT }],
        externalLinks: externalLinks || [],
        status: taskStatus,
        createdAt,
        updatedAt,
        routeId: routeId || NO_CONTENT,
        priority,
        weight: weight || NO_CONTENT,
        size: size || NO_CONTENT,
        sizeByCompartment,
        euId: getEuid(euid),
        equipmentId: equipmentId || NO_CONTENT,
        reservation: reservation || NO_CONTENT,
        emptyOrder: emptyOrder || NO_CONTENT
    };
    return commonDetails;
}

function filterDeliveryTask({
    task,
    selectedTimeZone,
    isClientTimezoneFlagEnabled = false,
    format,
    inventoryItems = []
}: {
    task: DeliveryTask | TwoPartTask;
    selectedTimeZone?: string;
    isClientTimezoneFlagEnabled?: boolean;
    format?: string;
    inventoryItems?: ApiInventoryItem[];
}) {
    const commonDetails = getCommonFilteredTaskDetail(task);

    const { deliveryLocation, deliveryContact, props } = task;
    const { deliveryWindow, deliveryServiceTime, deliveryInvoices } = props;
    const { lat, lng } = getTaskLocationLatLng(deliveryLocation);
    const deliveryFilteredTaskDetail = {
        customerName: deliveryLocation.name,
        contactPhone: deliveryContact?.phone || NO_CONTENT,
        contactEmail: deliveryContact?.email || NO_CONTENT,
        customerAddress: formatAddress(deliveryLocation),
        locationLat: lat,
        locationLng: lng,
        timeWindows: formatTimeWindowWithDate({
            timeWindow: deliveryWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled,
            format
        }),
        timeWindowsWithoutDate: formatTimeWindow({
            timeWindow: deliveryWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled,
            format
        }),
        inventoryItems: inventoryItems.filter(
            (item) => item.type === constants.taskTypes.DELIVERY
        ),
        serviceTime: formatServiceTime(deliveryServiceTime),
        invoicesAndPayments: deliveryInvoices ?? []
    };

    return {
        ...commonDetails,
        ...deliveryFilteredTaskDetail
    };
}

function filterPickupTask({
    task,
    selectedTimeZone,
    isClientTimezoneFlagEnabled = false,
    inventoryItems = [],
    format
}: {
    task: PickupTask | TwoPartTask;
    selectedTimeZone?: string;
    isClientTimezoneFlagEnabled?: boolean;
    inventoryItems?: ApiInventoryItem[];
    format?: string;
}) {
    const commonDetails = getCommonFilteredTaskDetail(task);

    const { pickupLocation, pickupContact, props } = task;
    const { pickupWindow, pickupServiceTime, pickupInvoices } = props;
    const { lat, lng } = getTaskLocationLatLng(pickupLocation);

    const pickupFilteredTaskDetail = {
        customerName: pickupLocation.name,
        contactPhone: pickupContact?.phone || NO_CONTENT,
        contactEmail: pickupContact?.email || NO_CONTENT,
        customerAddress: formatAddress(pickupLocation),
        locationLat: lat,
        locationLng: lng,
        timeWindows: formatTimeWindowWithDate({
            timeWindow: pickupWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled,
            format
        }),
        timeWindowsWithoutDate: formatTimeWindow({
            timeWindow: pickupWindow,
            selectedTimeZone,
            isClientTimezoneFlagEnabled,
            format
        }),
        inventoryItems: inventoryItems.filter(
            (item) => item.type === constants.taskTypes.PICKUP
        ),
        serviceTime: formatServiceTime(pickupServiceTime),
        invoicesAndPayments: pickupInvoices ?? []
    };

    return { ...commonDetails, ...pickupFilteredTaskDetail };
}

/**
 * Task metrics without depots included
 */
function removeDepotsFromTaskMetrics(
    depotTasks: ApiTask[],
    taskMetrics: Required<TaskMetrics>
) {
    const updatedMetrics = { ...taskMetrics };

    for (const depot of depotTasks) {
        updatedMetrics.total--;
        switch (depot.status) {
            case constants.taskStatus.UNASSIGNED:
                if (depot.routeId) {
                    updatedMetrics.planned--;
                } else {
                    updatedMetrics.unassigned--;
                }
                break;
            case constants.taskStatus.DISPATCHED:
            case constants.taskStatus.IN_PROGRESS:
                updatedMetrics.dispatched--;
                break;
            case constants.taskStatus.COMPLETED:
                updatedMetrics.completed--;
                break;
            case constants.taskStatus.CANCELLED:
                updatedMetrics.canceled--;
                break;
            default:
        }
    }

    return updatedMetrics;
}

/**
 * Checks task is planned status
 */
function isPlannedTask(task: ApiTask) {
    return (
        task?.status === constants.taskStatus.UNASSIGNED &&
        Boolean(task?.routeId)
    );
}

/**
 * Checks unassigned status is in array
 */
function includesUnassignedStatus(statusArray: TaskStatus[]) {
    return Boolean(statusArray?.includes(constants.taskStatus.UNASSIGNED));
}

/**
 * Checks planned status is in array
 */
function includesPlannedStatus(statusArray: TaskStatusCondition[]) {
    return Boolean(statusArray?.includes(constants.taskStatus.PLANNED));
}

/**
 * Converts web status to API status
 */
function getApiStatusFromWebStatus(
    selectedStatusFilters: TaskStatusCondition[]
) {
    const { taskStatus } = constants;
    if (isEmpty(selectedStatusFilters)) {
        return [
            taskStatus.UNASSIGNED,
            taskStatus.DISPATCHED,
            taskStatus.IN_PROGRESS,
            taskStatus.COMPLETED,
            taskStatus.CANCELLED
        ];
    }

    const webStatusToApiStatus: Record<number, number> = {
        [taskStatus.UNASSIGNED]: taskStatus.UNASSIGNED,
        [taskStatus.PLANNED]: taskStatus.UNASSIGNED,
        [taskStatus.DISPATCHED]: taskStatus.DISPATCHED,
        [taskStatus.COMPLETED]: taskStatus.COMPLETED,
        [taskStatus.CANCELLED]: taskStatus.CANCELLED
    };
    const updatedTaskStatus = new Set(
        selectedStatusFilters
            .filter((status) =>
                Object.keys(webStatusToApiStatus).includes(`${status}`)
            )
            .map((status) => webStatusToApiStatus[status])
    );
    return Array.from(updatedTaskStatus);
}

/**
 * Get task status, including planned status
 */
function getTaskStatus(task: ApiTask) {
    if (isPlannedTask(task)) {
        return constants.taskStatus.PLANNED as TaskStatusCondition.PLANNED;
    }
    return task?.status as unknown as TaskStatusCondition;
}

/**
 * Format API tasks to web tasks by adding taskStatus property
 */
function formatApiTasksToWebTasks(tasks: ApiTask[]) {
    return tasks.map((task) => {
        const taskStatus = getTaskStatus(task);
        return { ...task, taskStatus };
    });
}

/**
 * Filter tasks by taskStatus
 */
function filterTasksByTaskStatus(
    tasks: ApiTask[],
    selectedStatusFilters: (TaskStatusCondition | undefined)[]
) {
    if (isEmpty(selectedStatusFilters)) {
        return tasks;
    }

    return tasks.filter((task) => {
        return selectedStatusFilters.includes(task?.taskStatus);
    });
}

function checkIsDefaultEmptyContent(value: unknown) {
    return value === NO_CONTENT;
}

function filterTasksByRouteDate(
    unassignedPlanTasks: ApiTask[],
    selectedDate: string | null
) {
    return (unassignedPlanTasks ?? []).filter(
        ({ routeDate }) => routeDate === selectedDate
    );
}

export default {
    checkIsDeliveryTask,
    checkIsDepot,
    checkIsPickupTask,
    checkIsTwoPartTask,
    filterDeliveryTask,
    filterPickupTask,
    filterTasksByTaskStatus,
    formatApiTasksToWebTasks,
    formatServiceTime,
    formatTaskAddress,
    formatTaskName,
    formatTimestamp,
    formatTimeWindow,
    formatTimeWindowWithDate,
    getApiStatusFromWebStatus,
    getEuid,
    getTaskInventoryKey,
    getTaskLocationKey,
    getTaskLocationLatLng,
    getTaskStatus,
    includesPlannedStatus,
    includesUnassignedStatus,
    isPlannedTask,
    removeDepotsFromTaskMetrics,
    checkIsDefaultEmptyContent,
    filterTasksByRouteDate
};
